import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "app/rootReducer";
import { useAuth0 } from "@auth0/auth0-react";
import { Grid, Typography } from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { IppTextField } from "components/IppTextField";
import { IppAutocomplete } from "components/IppAutocomplete";
import { IppRichTextEditor } from "components/RichTextEditor/IppRichTextEditor";
import { ConvertDateOffset, GetJSDate } from "../../../utils/DateFunctions";
import {
  addCommitment,
  fetchCommitments,
  fetchCommitmentsByAssociatedContact,
  updCommitment,
} from "./CommitmentSlice";
import { fetchCommitmentStatusTypes } from "../commitmentStatusType/CommitmentStatusTypeSlice";
import { fetchCommitmentCategories } from "../commitmentCategory/CommitmentCategorySlice";
import { fetchClientCommitmentCategories } from "features/platform/admin/clientCommitmentCategories/ClientCommitmentCategorySlice";
import { fetchUsers } from "features/users/UsersSlice";
import { fetchStakeholderGroups } from "features/platform/groups/StakeholderGroupSlice";
import CommitmentResponsibilities from "components/CommitmentResponsibility";
import { ValidationSchema } from "./CommitmentFormValidation";
import { fetchUserRoles } from "features/roles/UserRoleSlice";
import { IppDatePicker } from "components/IppDatePicker";
import { IppPersonResponsible } from "components/IppPersonResponsible";
import { fetchContacts } from "features/platform/contacts/ContactSlice";
import { fetchCommitmentSources } from "../commitmentSource/CommitmentSourceSlice";
import { IppMultiTextField } from "components/IppMultiTextField";
import LoadingIndicator from "components/LoadingIndicator";
import { useTypedTranslation } from "utils/customHooks";
import { IppTokenMultiSelect } from "components/IppTokenMultiSelect";
import { fetchCommitmentPhasesByCommitment } from "../commitmentPhase/CommitmentPhaseSlice";
import { fetchPhases } from "features/platform/admin/phase/PhaseSlice";
import { IppFormDivider } from "components/IppFormDivider";
import { Contact } from "api/stakeholder/contactAPI";
import { fetchPriorities } from "features/datalists/PrioritySlice";
import { fetchChildTagsByRecordType } from "features/platform/admin/tag/TagSlice";
import { IppFormButtons } from "components/Buttons/IppFormButtons";
import { fetchStandardStatements } from "features/platform/admin/standardStatement/StandardStatementSlice";
import { PersonRenderOption } from "utils/renderFunctions";

interface CommitmentChildFormProps {
  closeAction: any; // action to close dialog
  setDidSaveInventory: any;
  parentTitle: string; // name of field the form is called from
  parentValue: any; // if called from child grid, init parent value
  parentID?: any;
  commitment?: any; // existing interaction to be edited
}

export const CommitmentChildForm = (props: CommitmentChildFormProps) => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const t = useTypedTranslation(["objPlt", "strGen", "objCom", "objStk"]);
  const [canSave, setCanSave] = useState(false);
  const [isEditing, setIsEditing] = useState(true);

  const { currentProfile } = useSelector((state: RootState) => state.profile);

  const {
    closeAction,
    setDidSaveInventory,
    parentTitle,
    parentID,
    commitment,
  } = props;

  let currentCommitment = commitment || {};

  const { commitmentList, commitmentsById } = useSelector(
    (state: RootState) => state.commitments
  );

  if (parentTitle === "Communication") {
    const currentCommitmentResult = commitmentList
      .map((id) => commitmentsById[id])
      .filter((commit) => commit.CommitmentID === commitment.CommitmentID);

    currentCommitment = currentCommitmentResult[0];
  }

  const {
    clientId,
    ft_all_ExternalPersonRes,
    ft_com_Phase,
    ft_all_Tag,
    ft_com_Priority,
    ft_com_Associated,
    ft_com_DateApplicable,
    ft_com_InternalNotes,
  } = useSelector((state: RootState) => state.client);

  const {
    commitmentStatusTypeList,
    commitmentStatusTypesById,
    isLoading: commitmentStatusIsLoading,
  } = useSelector((state: RootState) => state.commitmentStatusTypes);

  const commitmentStatusTypes = commitmentStatusTypeList.map(
    (id) => commitmentStatusTypesById[id]
  );

  const {
    priorityList,
    prioritiesById,
    isLoading: priorityIsLoading,
  } = useSelector((state: RootState) => state.priorities);

  const priorities = priorityList.map((id) => prioritiesById[id]);

  const { userList, usersById } = useSelector(
    (state: RootState) => state.users
  );

  //Filter for active users only
  const allUsers = userList.map((id) => usersById[id]);

  const {
    commitmentSourceList,
    commitmentSourcesById,
    isLoading: commitmentSourceIsLoading,
  } = useSelector((state: RootState) => state.commitmentSources);

  const commitmentSources = commitmentSourceList.map(
    (id) => commitmentSourcesById[id]
  );

  const { commitmentCategoryList, commitmentCategoriesById } = useSelector(
    (state: RootState) => state.commitmentCategories
  );

  const commitmentCategories = commitmentCategoryList.map(
    (id) => commitmentCategoriesById[id]
  );

  const {
    clientCommitmentCategoryList,
    clientCommitmentCategoriesById,
    isLoading: clientCommitmentCategoryIsLoading,
  } = useSelector((state: RootState) => state.clientCommitmentCategories);

  const clientCommitmentCategories = clientCommitmentCategoryList.map(
    (id) => clientCommitmentCategoriesById[id]
  );

  const { userRoleList, userRolesById } = useSelector(
    (state: RootState) => state.userRoles
  );

  // if non-admin, only show userRoles correspondering to Commitments Module (ModuleRoleID === 3 || ModuleRoleID === 4)
  const userRoles = currentProfile.IsClientAdmin
    ? userRoleList.map((id) => userRolesById[id])
    : userRoleList
        .map((id) => userRolesById[id])
        .filter((role) => role.ModuleRoleID === 3 || role.ModuleRoleID === 4);

  // list of project IDs available in this Module
  const accessibleProjectIDs = userRoles
    .filter((role) => role.UserAccountID === currentProfile.UserAccountID)
    .map((role) => role.ProjectID);

  const { projectList, projectsById } = useSelector(
    (state: RootState) => state.projects
  );

  // if admin, show all projects
  // if non-admin, only show projects included in accessibleProjectIds
  const projects = currentProfile.IsClientAdmin
    ? projectList.map((id) => projectsById[id])
    : projectList
        .map((id) => projectsById[id])
        .filter((project) => accessibleProjectIDs.includes(project.ProjectID));

  const { stakeholderGroupList, stakeholderGroupsById } = useSelector(
    (state: RootState) => state.stakeholderGroups
  );

  const groups = stakeholderGroupList.map((id) => stakeholderGroupsById[id]);

  const { contactList, contactsById } = useSelector(
    (state: RootState) => state.contacts
  );

  const contacts = contactList.map((id) => contactsById[id]);

  const { phaseList, phasesById } = useSelector(
    (state: RootState) => state.phase
  );
  const phases = phaseList.map((id) => phasesById[id]);

  const {
    commitmentPhaseList,
    commitmentPhasesById,
    isLoading: commPhaseIsLoading,
  } = useSelector((state: RootState) => state.commitmentPhases);

  const CommPhase = commitmentPhaseList.map((id) => commitmentPhasesById[id]);

  const {
    childTagList,
    childTagsById,
    isChildLoading: tagIsLoading,
  } = useSelector((state: RootState) => state.tag);

  const tags = ft_all_Tag ? childTagList.map((id) => childTagsById[id]) : [];

  let itemData = props.commitment || {};
  const [tagItems, setTagItems] = useState(
    itemData.CommitmentID && itemData.Tags ? itemData.Tags : undefined
  );

  const { isLoading: standardStatementIsLoading } = useSelector(
    (state: RootState) => state.standardStatement
  );

  if (Object.keys(currentCommitment).length > 0) {
    itemData = currentCommitment;
  } else {
    let thisDate = ConvertDateOffset(new Date());
    itemData.CommitmentTitle = "";
    itemData.CommitmentDetails = "";
    itemData.RecordedDate = thisDate.toISOString().slice(0, 10);
    itemData.ApplicableDate = thisDate.toISOString().slice(0, 10);
    itemData.CommitmentStatusTypeID = -1;
    itemData.CommitmentSourceID =
      parentTitle === t("objCom:objects.commitmentsource.source")
        ? parentID
        : -1;
    itemData.CommitmentReference = "";
    itemData.CommitmentCategoryID = -1;
    itemData.OverallResponsibility = "";
    itemData.ProjectID = -1;
    itemData.PersonResponsible = currentProfile.UserAccountID;
    itemData.PersonResponsibleName =
      currentProfile.FirstName + " " + currentProfile.Surname;
    itemData.LegalText = "";
    itemData.ContactID = null;
    itemData.GroupID =
      parentTitle === t("objPlt:objects.group.name") ? parentID : null;
    itemData.AssociatedContactID =
      parentTitle === t("objPlt:objects.contact.name") ? parentID : null;
    itemData.InternalNotes = "";
    itemData.PriorityID = null;
    itemData.Tags = [];
  }

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(fetchCommitmentStatusTypes(accessToken));
        dispatch(fetchCommitmentCategories(accessToken));
        dispatch(fetchClientCommitmentCategories(accessToken));
        dispatch(fetchUsers(accessToken, clientId));
        if (ft_com_Priority) {
          dispatch(fetchPriorities(accessToken));
        }
        if (parentTitle !== t("objCom:objects.commitmentsource.source")) {
          dispatch(fetchCommitmentSources(accessToken));
        }
        dispatch(fetchUserRoles(accessToken, currentProfile.UserAccountID));
        if (parentTitle !== "Communication" && ft_com_Associated) {
          if (
            parentTitle !== t("objPlt:objects.group.name") &&
            parentTitle !== t("objPlt:objects.contact.name")
          ) {
            dispatch(fetchStakeholderGroups(accessToken));
            dispatch(fetchContacts(accessToken));
            dispatch(fetchStandardStatements(accessToken));
          }
        } else {
          parentTitle === t("objPlt:objects.contact.name")
            ? dispatch(
                fetchCommitmentsByAssociatedContact(
                  accessToken,
                  itemData.ContactID
                )
              )
            : dispatch(fetchCommitments(accessToken));
        }
        if (ft_com_Phase) {
          dispatch(fetchPhases(accessToken));
          if (itemData.CommitmentID) {
            dispatch(
              fetchCommitmentPhasesByCommitment(
                accessToken,
                itemData.CommitmentID
              )
            );
          }
        }
        if (ft_all_Tag) {
          dispatch(fetchChildTagsByRecordType(accessToken, "Commitment"));
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [dispatch, getAccessTokenSilently]);

  // convert tags to objects for multi autocomplete field
  useEffect(() => {
    // effect
    if (
      ft_all_Tag &&
      !tagIsLoading &&
      tags &&
      tags.length > 0 &&
      itemData.CommitmentID &&
      itemData.Tags
    ) {
      let newList = tags.filter((item) => itemData.Tags.includes(item.Name));
      setTagItems(newList);
    }
  }, [isEditing, tagIsLoading]);

  const onSub = (values: any) => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(addCommitment(accessToken, values, false));
        setDidSaveInventory(true);
        closeAction();
      } catch (e) {
        console.error(e);
      }
    })();
  };

  let submitFunc = onSub;

  if (Object.keys(currentCommitment).length > 0) {
    submitFunc = (values: any) => {
      (async () => {
        try {
          const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
            },
          });
          dispatch(
            updCommitment(
              accessToken,
              values.CommitmentID,
              values,
              false,
              parentID
            )
          );
          closeAction();
        } catch (e) {
          console.error(e);
        }
      })();
    };
  }

  const formik = useFormik({
    initialValues: itemData,
    validationSchema: ValidationSchema(),
    onSubmit: submitFunc,
  });

  let editForm =
    commitmentStatusIsLoading ||
    commitmentSourceIsLoading ||
    commPhaseIsLoading ||
    clientCommitmentCategoryIsLoading ||
    priorityIsLoading ||
    tagIsLoading ||
    standardStatementIsLoading ? (
      <LoadingIndicator />
    ) : (
      <form noValidate onSubmit={formik.handleSubmit}>
        <Grid container spacing={1}>
          {Object.keys(currentCommitment).length > 0 && isEditing && (
            <Grid item xs={12}>
              <Typography variant="h6">
                {t("strGen:buttons.edit")} {t("objCom:objects.commitment.name")}
              </Typography>
            </Grid>
          )}
          <Grid item xs={6}>
            <IppTextField
              id="CommitmentTitle"
              label={t("objCom:objects.commitment.name")}
              required={true}
              value={formik.values.CommitmentTitle}
              onChangeFunction={formik.handleChange}
              touchedExpression={formik.touched.CommitmentTitle}
              errorsExpression={formik.errors.CommitmentTitle}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
            />
          </Grid>
          {ft_com_Priority && (
            <Grid item xs={6}>
              <IppAutocomplete
                id="PriorityID"
                options={priorities}
                required={false}
                value={priorities.find((obj) => {
                  return obj.PriorityID === formik.values.PriorityID;
                })}
                onChangeFunction={(event: ChangeEvent, newValue: any) => {
                  if (newValue) {
                    formik.setFieldValue("PriorityID", newValue.PriorityID);
                  } else {
                    formik.setFieldValue("PriorityID", null);
                  }
                }}
                label={t("objCom:objects.commitment.fields.priority")}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                optionLabelFunction={(option: any) => option.PriorityName}
                errorFunction={formik.errors.PriorityID}
                touchedFunction={formik.touched.PriorityID}
                textValueFunction={
                  priorities.find(
                    (priority) =>
                      priority.PriorityID === formik.values.PriorityID
                  )?.PriorityName
                }
              />
            </Grid>
          )}
          <Grid item xs={6}>
            <IppDatePicker
              id="RecordedDate"
              label={t("objPlt:platformwide.fields.daterecorded")}
              required={true}
              value={ConvertDateOffset(formik.values.RecordedDate)}
              onChangeFunction={(newValue: any) => {
                formik.setFieldValue("RecordedDate", GetJSDate(newValue), true);
                formik.setFieldTouched("RecordedDate", true, false);
              }}
              errorsExpression={formik.errors.RecordedDate}
              touchedExpression={formik.touched.RecordedDate}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
            />
          </Grid>
          {ft_com_DateApplicable && (
            <Grid item xs={6}>
              <IppDatePicker
                id="ApplicableDate"
                required={false}
                label={t("objCom:objects.commitment.fields.dateapplicable")}
                value={ConvertDateOffset(formik.values.ApplicableDate)}
                onChangeFunction={(newValue: any) => {
                  formik.setFieldValue(
                    "ApplicableDate",
                    GetJSDate(newValue),
                    true
                  );
                  formik.setFieldTouched("ApplicableDate", true, true);
                }}
                errorsExpression={formik.errors.ApplicableDate}
                touchedExpression={formik.touched.ApplicableDate}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
              />
            </Grid>
          )}
          <Grid item xs={6}>
            <IppAutocomplete
              id="CommitmentStatusTypeID"
              required={true}
              options={commitmentStatusTypes}
              value={commitmentStatusTypes.find((obj) => {
                return (
                  obj.CommitmentStatusTypeID ===
                  formik.values.CommitmentStatusTypeID
                );
              })}
              onChangeFunction={(event: ChangeEvent, newValue: any) => {
                if (newValue) {
                  formik.setFieldValue(
                    "CommitmentStatusTypeID",
                    newValue.CommitmentStatusTypeID
                  );
                } else {
                  formik.setFieldValue("CommitmentStatusTypeID", -1);
                }
              }}
              label={t("objCom:objects.commitment.fields.status")}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              optionLabelFunction={(option: any) =>
                option.CommitmentStatusTypeName
              }
              errorFunction={formik.errors.CommitmentStatusTypeID}
              touchedFunction={formik.touched.CommitmentStatusTypeID}
              textValueFunction={formik.values.CommitmentStatusTypeName}
            />
          </Grid>
          <Grid item xs={6}>
            <IppAutocomplete
              id="CommitmentCategoryID"
              options={clientCommitmentCategories}
              required={true}
              value={commitmentCategories.find((obj) => {
                return (
                  obj.CommitmentCategoryID ===
                  formik.values.CommitmentCategoryID
                );
              })}
              onChangeFunction={(event: ChangeEvent, newValue: any) => {
                if (newValue) {
                  formik.setFieldValue(
                    "CommitmentCategoryID",
                    newValue.CommitmentCategoryID
                  );
                } else {
                  formik.setFieldValue("CommitmentCategoryID", -1);
                }
              }}
              label={t("objCom:objects.commitment.fields.category")}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              optionLabelFunction={(option: any) =>
                option.CommitmentCategoryName
              }
              errorFunction={formik.errors.CommitmentCategoryID}
              touchedFunction={formik.touched.CommitmentCategoryID}
              textValueFunction={formik.values.CommitmentCategoryName}
            />
          </Grid>
          <Grid item xs={12}>
            <IppAutocomplete
              id="CommitmentSourceID"
              disabled={
                parentTitle === t("objCom:objects.commitmentsource.source")
                  ? true
                  : false
              }
              options={commitmentSources}
              required={true}
              value={commitmentSources.find((obj) => {
                return (
                  obj.CommitmentSourceID === formik.values.CommitmentSourceID
                );
              })}
              onChangeFunction={(event: ChangeEvent, newValue: any) => {
                if (newValue) {
                  formik.setFieldValue(
                    "CommitmentSourceID",
                    newValue.CommitmentSourceID
                  );
                } else {
                  formik.setFieldValue("CommitmentSourceID", -1);
                }
              }}
              label={t("objCom:objects.commitmentsource.name", {
                sourcecount: 1,
              })}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              optionLabelFunction={(option: any) => option.CommitmentSourceName}
              errorFunction={formik.errors.CommitmentSourceID}
              touchedFunction={formik.touched.CommitmentSourceID}
              textValueFunction={formik.values.CommitmentSourceName}
              toolTip="To select a Source, it must first be created under the Sources menu"
            />
          </Grid>
          <Grid item xs={12}>
            <IppMultiTextField
              id="CommitmentReference"
              label={t("objCom:objects.commitment.fields.reference")}
              required={false}
              options={[]}
              noOptionsText={t("strGen:components.nooptionstext")}
              value={formik.values.CommitmentReference}
              setValue={(newValue: any) =>
                formik.setFieldValue("CommitmentReference", newValue.toString())
              }
              touchedFunction={formik.touched.CommitmentReference}
              errorFunction={formik.errors.CommitmentReference}
              isEditing={true}
              setIsEditing={null}
              multiple={true}
              autoPopulate={false}
              freeSolo={true}
            />
          </Grid>
          {ft_com_Associated && (
            <>
              <Grid item xs={6}>
                <IppAutocomplete
                  id="GroupID"
                  options={groups}
                  value={groups.find((obj) => {
                    return obj.GroupID === formik.values.GroupID;
                  })}
                  disabled={
                    parentTitle === t("objPlt:objects.group.name")
                      ? true
                      : false
                  }
                  onChangeFunction={(event: ChangeEvent, newValue: any) => {
                    if (newValue) {
                      formik.setFieldValue("GroupID", newValue.GroupID);
                    } else {
                      formik.setFieldValue("GroupID", null);
                    }
                  }}
                  label={t(
                    "objPlt:objects.group.fields.associatedgroupifapplicable"
                  )}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  optionLabelFunction={(option: any) => option.GroupName}
                  errorFunction={formik.errors.GroupID}
                  touchedFunction={formik.touched.GroupID}
                  textValueFunction={formik.values.GroupName}
                />
              </Grid>
              <Grid item xs={6}>
                <IppAutocomplete
                  id="AssociatedContactID"
                  options={contacts}
                  renderOption={(props: any, option: Contact) =>
                    PersonRenderOption(
                      props,
                      option,
                      [
                        option.Email,
                        option.FirstGroupName,
                        option.PrimaryPhone,
                        option.ContactTitle,
                      ],
                      `* ${t("objPlt:objects.contact.missingname")} *`
                    )
                  }
                  value={contacts.find((obj) => {
                    return obj.ContactID === formik.values.AssociatedContactID;
                  })}
                  disabled={
                    parentTitle === t("objPlt:objects.contact.name")
                      ? true
                      : false
                  }
                  onChangeFunction={(event: ChangeEvent, newValue: any) => {
                    if (newValue) {
                      formik.setFieldValue(
                        "AssociatedContactID",
                        newValue.ContactID
                      );
                    } else {
                      formik.setFieldValue("AssociatedContactID", null);
                    }
                  }}
                  label={t("objPlt:objects.contact.fields.associatedcontact", {
                    count: 1,
                  })}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  optionLabelFunction={(option: any) =>
                    option.ContactName?.trim() !== ""
                      ? option.ContactName
                      : `* ${t("objPlt:objects.contact.missingname")} * (${
                          option.Email && option.Email?.trim() !== ""
                            ? option.Email
                            : t("objPlt:objects.contact.missingemail")
                        })`
                  }
                  errorFunction={formik.errors.AssociatedContactID}
                  touchedFunction={formik.touched.AssociatedContactID}
                  textValueFunction={formik.values.ContactName}
                />
              </Grid>
            </>
          )}
          {ft_all_Tag && tags.length > 0 && (
            <Grid item xs={6}>
              <IppTokenMultiSelect
                id="Tags"
                label={t("objPlt:objects.tag.name_other", {
                  count: formik.values.Tags ? formik.values.Tags.length : 1,
                })}
                required={false}
                options={tags}
                noOptionsText={t("strGen:components.nooptionstext")}
                value={tagItems}
                setValue={(newValue: any) => {
                  setTagItems(newValue);
                  formik.setFieldValue("Tags", newValue);
                }}
                touchedFunction={formik.touched.Tags}
                errorFunction={formik.errors.Tags}
                isEditing={true}
                optionLabelFunction={(option: any) => option.Name}
                setIsEditing={null}
                toolTip={t("strGen:tooltips.tags")}
              />
            </Grid>
          )}
          <IppFormDivider
            title={t("objCom:objects.commitment.headers.projectdetails")}
          />
          <Grid item xs={6}>
            <IppAutocomplete
              id="ProjectID"
              options={projects}
              required={true}
              value={projects.find((obj) => {
                return obj.ProjectID === formik.values.ProjectID;
              })}
              onChangeFunction={(event: ChangeEvent, newValue: any) => {
                if (newValue) {
                  formik.setFieldValue("ProjectID", newValue.ProjectID);
                } else {
                  formik.setFieldValue("ProjectID", -1);
                }
              }}
              label={t("objPlt:objects.project.name")}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              optionLabelFunction={(option: any) => option.ProjectName}
              errorFunction={formik.errors.ProjectID}
              touchedFunction={formik.touched.ProjectID}
              textValueFunction={formik.values.ProjectName}
            />
          </Grid>
          {ft_com_Phase && (
            <Grid item xs={6}>
              <IppTokenMultiSelect
                id="CommitmentPhase"
                label={t("objPlt:platformwide.phase.name", {
                  count: formik.values.CommitmentPhase
                    ? formik.values.CommitmentPhase.length
                    : 1,
                })}
                required={false}
                options={phases}
                noOptionsText={t("strGen:components.nooptionstext")}
                value={
                  formik.values.CommitmentPhase
                    ? formik.values.CommitmentPhase
                    : CommPhase
                }
                setValue={(newValue: any) => {
                  formik.setFieldValue("CommitmentPhase", newValue);
                }}
                touchedFunction={formik.touched.CommitmentPhase}
                errorFunction={formik.errors.CommitmentPhase}
                isEditing={true}
                optionLabelFunction={(option: any) => option.Name}
                setIsEditing={null}
              />
            </Grid>
          )}
          <IppFormDivider
            title={t("objCom:objects.commitment.headers.commitmentowner")}
          />
          <Grid item xs={6}>
            <IppAutocomplete
              id="OverallResponsibility"
              required={true}
              options={CommitmentResponsibilities}
              value={formik.values.OverallResponsibility}
              textValueFunction={formik.values.OverallResponsibility}
              onChangeFunction={(event: ChangeEvent, newValue: any) => {
                if (newValue) {
                  if (
                    newValue === t("objCom:objects.commitment.fields.corporate")
                  ) {
                    formik.setFieldValue("ContactID", null);
                  }
                  formik.setFieldValue("OverallResponsibility", newValue);
                } else {
                  formik.setFieldValue("OverallResponsibility", "");
                  formik.setFieldValue("ContactID", null);
                }
              }}
              touchedFunction={formik.touched.OverallResponsibility}
              errorFunction={formik.errors.OverallResponsibility}
              label={t(
                "objCom:objects.commitment.fields.overallresponsibility"
              )}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
            />
          </Grid>
          <Grid item xs={6}>
            <IppPersonResponsible
              id="PersonResponsible"
              value={allUsers.find((obj) => {
                return obj.UserAccountID === formik.values.PersonResponsible;
              })}
              onChangeFunction={(event: ChangeEvent, newValue: any) => {
                if (newValue) {
                  formik.setFieldValue(
                    "PersonResponsible",
                    newValue.UserAccountID
                  );
                } else {
                  formik.setFieldValue("PersonResponsible", null);
                }
              }}
              label={t("objCom:objects.commitment.fields.personresponsible")}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              optionLabelFunction={(option: any) =>
                option.FirstName + " " + option.Surname
              }
              errorFunction={formik.errors.PersonResponsible}
              touchedFunction={formik.touched.PersonResponsible}
              textValueFunction={formik.values.PersonResponsibleName}
              projectID={formik.values.ProjectID}
            />
          </Grid>
          {ft_all_ExternalPersonRes && (
            <Grid item xs={6}>
              <IppAutocomplete
                id="ContactID"
                label={t(
                  "objCom:objects.commitment.fields.additionalpersonresponsible"
                )}
                options={contacts}
                renderOption={(props: any, option: Contact) =>
                  PersonRenderOption(
                    props,
                    option,
                    [
                      option.Email,
                      option.FirstGroupName,
                      option.PrimaryPhone,
                      option.ContactTitle,
                    ],
                    `* ${t("objPlt:objects.contact.missingname")} *`
                  )
                }
                value={contacts.find((obj) => {
                  return obj.ContactID === formik.values.ContactID;
                })}
                onChangeFunction={(event: ChangeEvent, newValue: any) => {
                  if (newValue) {
                    formik.setFieldValue("ContactID", newValue.ContactID);
                  } else {
                    formik.setFieldValue("ContactID", null);
                  }
                }}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                optionLabelFunction={(option: any) => option.ContactName}
                errorFunction={formik.errors.ContactID}
                touchedFunction={formik.touched.ContactID}
                textValueFunction={formik.values.ContactName}
                toolTip="An external contact who shares responsibility for this record but does have access to the platform"
              />
            </Grid>
          )}
          {ft_com_InternalNotes && (
            <Grid item xs={12}>
              <IppRichTextEditor
                id="InternalNotes"
                label={t("objCom:objects.commitment.fields.internalnotes")}
                value={formik.values.InternalNotes}
                touchedExpression={formik.touched.InternalNotes}
                errorsExpression={formik.errors.InternalNotes}
                isEditing={isEditing}
                setFieldValue={formik.setFieldValue}
                height={150}
                toolTip="Remarks/notes meant for internal reference only"
                standardStatementsRecordType="CommitmentInternalNotes"
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <IppRichTextEditor
              id="CommitmentDetails"
              label={t("objCom:objects.commitment.fields.commitmentdetails")}
              value={formik.values.CommitmentDetails}
              isEditing={isEditing}
              touchedExpression={formik.touched.CommitmentDetails}
              errorsExpression={formik.errors.CommitmentDetails}
              setFieldValue={formik.setFieldValue}
              height={150}
              standardStatementsRecordType="CommitmentDetails"
            />
          </Grid>
          <Grid item xs={12}>
            <IppRichTextEditor
              id="LegalText"
              label={t("objCom:objects.commitment.fields.legaltext")}
              value={formik.values.LegalText}
              isEditing={isEditing}
              touchedExpression={formik.touched.LegalText}
              errorsExpression={formik.errors.LegalText}
              setFieldValue={formik.setFieldValue}
              standardStatementsRecordType="CommitmentLegalText"
            />
          </Grid>
          <Grid item xs={12}>
            <IppFormButtons
              isEditing={true}
              setIsEditing={null}
              isAdding={!(itemData?.CommitmentID ?? null)}
              submitDisabled={canSave}
              showCancel={true}
              resetFunction={closeAction}
            />
          </Grid>
        </Grid>
      </form>
    );

  return <div id="commitment-child-form">{editForm}</div>;
};
